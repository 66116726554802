@tailwind base;

* {
  box-sizing: border-box;
}

@tailwind components;
/* 
.ant-table-row > td {
  word-break: break-all
} */

@tailwind utilities;

.menu-slide-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.menu-slide-enter-active {
  max-height: 500px; /* Adjust as necessary */
  opacity: 1;
}

.menu-slide-exit {
  max-height: 500px; /* Adjust as necessary */
  opacity: 1;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.menu-slide-exit-active {
  max-height: 0;
  opacity: 0;
}